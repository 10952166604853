import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingComponent } from './components/pages/landing/landing.component';
import { DriverComponent } from './components/pages/driver/driver.component';
import { SellerComponent } from './components/pages/seller/seller.component';
import { UserComponent } from './components/pages/user/user.component';
import { UserHomeComponent } from './components/pages/user-home/user-home.component';
import { UserLoginComponent } from './components/pages/user-login/user-login.component';
import { UserSignupComponent } from './components/pages/user-signup/user-signup.component';
import { DeleteComponent } from './components/pages/delete/delete.component';
import { DeleteDriverComponent } from './components/pages/delete-driver/delete-driver.component';
import { DeleteUserComponent } from './components/pages/delete-user/delete-user.component';

const routes: Routes = [
  {
    path: '',
    component: LandingComponent,
    children: [
      {
        path: 'driver',
        component: DriverComponent,
      },
      {
        path: 'seller',
        component: SellerComponent,
      },
      {
        path: 'user',
        component: UserComponent,
        children: [
          {
            path: 'login',
            component: UserLoginComponent
          },
          {
            path: 'signup',
            component: UserSignupComponent
          },
          {
            path: '',
            component: UserHomeComponent,
          }
        ]
      },
      {
        path: 'delete',
        component: DeleteComponent,
        children: [
          {
            path: 'driver',
            component: DeleteDriverComponent
          },
          {
            path: '',
            component: DeleteUserComponent
          }
        ]
      },
      {
        path: '',
        redirectTo: '/user', pathMatch: 'full'
      }
    ]
  },
  { path: '**', redirectTo: '' },
];

const driverRoutes = [
  {
    path: '',
    component: LandingComponent,
    children: [
      {
        path: '',
        component: DriverComponent
      }
    ]
  },
  { path: '**', redirectTo: '' },
];

const sellerRoutes = [
  {
    path: '',
    component: LandingComponent,
    children: [
      {
        path: '',
        component: SellerComponent
      }
    ]
  },
  { path: '**', redirectTo: '' },
];

const userRoutes = [
  {
    path: '',
    component: LandingComponent,
    children: [
      {
        path: '',
        component: UserComponent,
        children: [
          {
            path: 'login',
            component: UserLoginComponent
          },
          {
            path: 'signup',
            component: UserSignupComponent
          },
          {
            path: '',
            component: UserHomeComponent,
          }
        ]
      }
    ]
  },
  { path: '**', redirectTo: '' },
];

const deleteUserRoutes = [
  {
    path: '',
    component: LandingComponent,
    children: [
      {
        path: '',
        component: DeleteComponent,
        children: [
          {
            path: '',
            component: DeleteUserComponent,
          }
        ]
      }
    ]
  },
  { path: '**', redirectTo: '' },
];

const deleteDriverRoutes = [
  {
    path: '',
    component: LandingComponent,
    children: [
      {
        path: '',
        component: DeleteComponent,
        children: [
          {
            path: '',
            component: DeleteDriverComponent,
          }
        ]
      }
    ]
  },
  { path: '**', redirectTo: '' },
];

function getRoutes() {
  if (window.location.hostname.includes('seller.signup')) {
    return sellerRoutes;
  }

  if (window.location.hostname.includes('driver.signup')) {
    return driverRoutes;
  }

  if (window.location.hostname.includes('user.signup') || window.location.hostname.includes('signup.demo-ecom')) {
    return userRoutes;
  }

  if (window.location.hostname.includes('user.delete')) {
    return deleteUserRoutes;
  }

  if (window.location.hostname.includes('driver.delete')) {
    return deleteDriverRoutes;
  }

  return routes;
}

@NgModule({
  imports: [RouterModule.forRoot(getRoutes())],
  exports: [RouterModule]
})
export class AppRoutingModule { }
