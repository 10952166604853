import { Component } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { Title } from '@angular/platform-browser';

@Component({
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent {
  activeStep$ = this.userService.step$;

  constructor(private userService: UserService, private titleService: Title) {
    this.titleService.setTitle('Digitally2u User Registration');
  }
}
