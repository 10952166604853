<div class="container">
    <div class="mx-auto text-center mt-6 w-400">
        <img src="./assets/images/MyQM Logo.svg" height="90" class="mt-6">

        <h2 class="font-semibold pt-6">{{'MyQM.Welcome' | translate}}!</h2>

        <div class="flex flex-column gap-3">
            <p-button routerLink="login" [label]="'MyQM.Login' | translate" styleClass="btn-custom-primary w-full"
                [style]="{'color': 'black'}"></p-button>
            <p-button routerLink="signup" [label]="'MyQM.NewSignUp' | translate"
                styleClass="btn-custom-primary-outlined border-2 w-full" [outlined]="true"
                [style]="{'color': 'black'}"></p-button>
        </div>

        <!--<div class="flex flex-column mt-6">
            <span class="text-xl pt-6">{{'MyQM.SubsidaryOf' | translate}}</span>
            <img src="./assets/images/QM_Logo.svg" height="90" class="mt-2" />
        </div>-->
    </div>
</div>