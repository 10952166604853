import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PhoneCode, Source } from 'src/app/shared/models/enum.model';
import { RegistrationInfo, SendUserSmsCode, UserLoginInfo, VerifyUserCode } from '../shared/models/api-input.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private apiUrl = environment.TMS_API_URL;
  private tncUrl = environment.TNC_URL;

  private stepSubject: BehaviorSubject<string>;
  public step$: Observable<string>;

  private callbackUrl = ''; //For redirection after user successfully login
  private loginSource: string = Source.QMI;

  private loginToken: string = '';
  private imageToken: string = '';
  private captchaX: number = 0;
  private captchaY: number = 0;
  private phoneCode: string = PhoneCode.MYS;
  private phone: string = '';
  private isQmMember: boolean = false;
  private hasPasswordLogin: boolean = false;
  private isPasswordLogin: boolean = false;
  private otp: string = '';

  //For referral link entrance
  private isReferralLink: boolean = false;
  private referralCode: string = '';

  constructor(private http: HttpClient) {
    this.stepSubject = new BehaviorSubject<string>('');
    this.step$ = this.stepSubject.asObservable();
  }

  redirect(step: string) {
    this.stepSubject.next(step);
  }

  setCallbackUrl(callbackUrl: string) {
    this.callbackUrl = decodeURIComponent(callbackUrl);
    localStorage.setItem('sso_callbackUrl', this.callbackUrl);
  }

  getCallbackUrl() {
    return this.callbackUrl || (localStorage.getItem('sso_callbackUrl') || 'https://demo-ecom.digitally2u.com/sso-login');
  }

  getCallbackUrlWithToken(token: string) {
    let url = new URL(this.getCallbackUrl());
    url.searchParams.append('token', token);
    return url.toString();
  }

  getLoginSource() {
    return this.loginSource;
  }

  setLoginSource(loginSource: string) {
    this.loginSource = loginSource;
  }

  setLoginToken(loginToken: string) {
    this.loginToken = loginToken;
  }

  getLoginToken() {
    return this.loginToken;
  }

  isUserLogin() {
    return this.loginToken !== undefined && this.loginToken !== '';
  }

  setCaptchaInfo(imageToken: string, x: number, y: number) {
    this.imageToken = imageToken;
    this.captchaX = x;
    this.captchaY = y;
  }

  getImageToken() {
    return this.imageToken;
  }

  getCaptchaX() {
    return this.captchaX;
  }

  getCaptchaY() {
    return this.captchaY;
  }

  setPhoneInfo(phoneCode: string, phone: string) {
    this.phoneCode = phoneCode;
    this.phone = phone;
  }

  getPhoneCode() {
    return this.phoneCode;
  }

  getPhone() {
    return this.phone;
  }

  setIsQmMember(isQmMember: boolean) {
    this.isQmMember = isQmMember;
  }

  getIsQmMember() {
    return this.isQmMember;
  }

  setHasPasswordLogin(hasPasswordLogin: boolean) {
    this.hasPasswordLogin = hasPasswordLogin;
  }

  getHasPasswordLogin() {
    return this.hasPasswordLogin;
  }

  setIsPasswordLogin(isPasswordLogin: boolean) {
    this.isPasswordLogin = isPasswordLogin;
  }

  getIsPasswordLogin() {
    return this.isPasswordLogin;
  }

  setOtp(otp: string) {
    this.otp = otp;
  }

  getOtp() {
    return this.otp;
  }

  setIsReferralLink(isReferralLink: boolean) {
    this.isReferralLink = isReferralLink;
  }

  getIsReferralLink() {
    return this.isReferralLink;
  }

  setReferralCode(referralCode: string) {
    this.referralCode = referralCode;
  }

  getReferralCode() {
    return this.referralCode;
  }

  sendCode(sendSmsCode: SendUserSmsCode) {
    return this.http
      .post<any>(`${this.apiUrl}/web/v1/user/sms/sendCode`, sendSmsCode)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          console.log(err);
          throw 'error in source. Details: ' + err;
        })
      );
  }

  verifyCode(verifyCode: VerifyUserCode) {
    return this.http
      .post<any>(`${this.apiUrl}/web/v1/user/sms/verifyCode`, verifyCode)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          console.log(err);
          throw 'error in source. Details: ' + err;
        })
      );
  }

  verifyPhone(phone: string) {
    return this.http
      .post<any>(`${this.apiUrl}/web/v1/user/verifyPhone`, {
        phone: phone
      })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          console.log(err);
          throw 'error in source. Details: ' + err;
        })
      );
  }

  login(loginInfo: UserLoginInfo) {
    return this.http
      .post<any>(`${this.apiUrl}/web/v1/user/login`, loginInfo)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          console.log(err);
          throw 'error in source. Details: ' + err;
        })
      );
  }

  register(registrationInfo: RegistrationInfo) {
    return this.http
      .post<any>(`${this.apiUrl}/web/v1/user/register`, registrationInfo)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          console.log(err);
          throw 'error in source. Details: ' + err;
        })
      );
  }

  sendCodePassword(phoneCode: string, phone: string) {
    return this.http
      .post<any>(`${this.apiUrl}/web/v1/user/sms/sendCodePw`, {
        phoneCode: phoneCode,
        phone: phone
      })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          console.log(err);
          throw 'error in source. Details: ' + err;
        })
      );
  }

  updatePassword(phone: string, code: string, password: string) {
    return this.http
      .post<any>(`${this.apiUrl}/web/v1/user/updatePassword`, {
        phone: phone,
        code: code,
        password: password
      })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          console.log(err);
          throw 'error in source. Details: ' + err;
        })
      );
  }

  deleteAccount(reason: string, reasonContent: string) {
    return this.http
      .post<any>(`${this.apiUrl}/web/v1/user/delete`, {
        reason: reason,
        reasonContent: reasonContent
      })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          console.log(err);
          throw 'error in source. Details: ' + err;
        })
      );
  }
}
